import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/458226908"
    bibleGroupSrc="https://www.docdroid.net/wJi9Yv3/bible-group-homework-9-13-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Gathered and Scattered - Together is Better" />
  </Layout>
)

export default SermonPost
